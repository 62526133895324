var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c("div", { staticClass: "kt-subheader__main" }, [
          _c(
            "h3",
            { staticClass: "kt-subheader__title text-nowrap text-truncate" },
            [
              _c("SVGIcon", {
                staticClass: "mt-2 mr-3",
                attrs: { "hex-color": "#0f88ef", name: "communication" },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.message
                      ? _vm.message.messageSubject || "Communication"
                      : "Communication"
                  ) +
                  " "
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "kt-subheader__toolbar" }, [
          _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
            _vm.formattedMessage &&
            !_vm.formattedMessage.deleted &&
            _vm.canDelete
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-label-danger btn-bold btn-icon",
                    attrs: { type: "button", disabled: _vm.saving },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.setMessageDeleted(true)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-trash-alt" })]
                )
              : _vm._e(),
            _vm.formattedMessage &&
            _vm.formattedMessage.deleted &&
            _vm.canDelete
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-label-success btn-bold btn-icon",
                    attrs: { type: "button", disabled: _vm.saving },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.setMessageDeleted(false)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-history" })]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm.formattedMessage
      ? _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", { staticClass: "kt-portlet__body" }, [
                    !_vm.formattedMessage.messageBody
                      ? _c("h6", [_vm._v(" No message body. ")])
                      : _c(
                          "div",
                          { staticClass: "w-100 markdown-container" },
                          [
                            _c("VueShowdown", {
                              staticClass: "markdown",
                              attrs: {
                                markdown: _vm.bodyExpanded
                                  ? _vm.formattedMessage.messageBody
                                  : `${_vm.formattedMessage.messageBody.substring(
                                      0,
                                      1000
                                    )}...`,
                                flavor: "github",
                                options: { emoji: true },
                              },
                            }),
                          ],
                          1
                        ),
                    _vm.canExpandBody
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "kt-link kt-link--state kt-link--primary pull-right",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.bodyExpanded = !_vm.bodyExpanded
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.bodyExpanded ? "Collapse" : "Expand")
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm.attachments.length
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.attachments, function (attachment, idx) {
                        return _c(
                          "div",
                          { key: `attachment_${idx}`, staticClass: "col-4" },
                          [
                            _c("div", { staticClass: "kt-portlet" }, [
                              _c(
                                "div",
                                { staticClass: "kt-portlet__body py-3" },
                                [
                                  _c("div", { staticClass: "kt-widget4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget4__item" },
                                      [
                                        _vm._m(0, true),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "kt-widget4__title kt-font-bolder",
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              href: attachment.fileBucketPath,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(attachment.fileName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "kt-widget4__tools" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-clean btn-icon btn-icon-md",
                                                attrs: {
                                                  href: attachment.fileBucketPath,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "flaticon-download",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "kt-chat" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _vm._m(1),
                    _vm.sortedComments.length > 0
                      ? _c("div", { staticClass: "kt-portlet__body" }, [
                          _c(
                            "div",
                            { attrs: { id: "chats" } },
                            _vm._l(_vm.comments, function (comment) {
                              return _c(
                                "div",
                                {
                                  key: `comment_${comment.messageCommentId}`,
                                  staticClass: "kt-chat__messages",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "kt-chat__message",
                                      class: comment.fromMe
                                        ? "kt-chat__message--right"
                                        : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "kt-chat__text p-3",
                                          class: comment.fromMe
                                            ? "kt-bg-light-brand"
                                            : "kt-bg-light-success",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(comment.messageComment) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-chat__user pt-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "kt-chat__datetime",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(comment.createdAt) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-muted ml-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(comment.author) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm.sortedComments.length == 0
                      ? _c("div", { staticClass: "kt-portlet__body" }, [
                          _c("h6", [_vm._v(" No replies yet. ")]),
                        ])
                      : _vm._e(),
                    _vm.formattedMessage.allowReplies
                      ? _c("div", { staticClass: "kt-portlet__body" }, [
                          _c(
                            "div",
                            { staticClass: "form-group form-group-last" },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newComment,
                                    expression: "newComment",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  placeholder: "Add a reply",
                                  rows: "1",
                                },
                                domProps: { value: _vm.newComment },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.newComment = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "row w-100 pt-5" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-brand btn-sm btn-upper btn-bold",
                                  class: {
                                    "kt-spinner kt-spinner--sm kt-spinner--light":
                                      _vm.saving,
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.newComment || _vm.saving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.saveComment.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Post comment ")]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", { staticClass: "kt-portlet__body" }, [
                    _c("div", { staticClass: "tab-content" }, [
                      _c("div", { staticClass: "kt-widget6" }, [
                        _c("div", { staticClass: "kt-widget6__body" }, [
                          _c("div", { staticClass: "kt-widget6__item" }, [
                            _c("span", [_vm._v("Subject")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.formattedMessage.messageSubject)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "kt-widget6__item" }, [
                            _c("span", [_vm._v("From")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.formattedMessage.author)),
                            ]),
                          ]),
                          _c("div", { staticClass: "kt-widget6__item" }, [
                            _c("span", [_vm._v("Sent on")]),
                            _c(
                              "span",
                              { staticClass: "kt-font-bold kt-font-info" },
                              [_vm._v(_vm._s(_vm.formattedMessage.createdAt))]
                            ),
                          ]),
                          _vm.schoolStaffId
                            ? _c("div", { staticClass: "kt-widget6__item" }, [
                                _c("span", [_vm._v("Sent to")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formattedMessage.isSchoolAnnouncement
                                        ? "School Wide"
                                        : `${_vm.formattedRecipients.length} Recipients`
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "kt-widget6__item" }, [
                            _c("span", [_vm._v("Visibility")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formattedMessage.public
                                    ? "Public"
                                    : "Private"
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "kt-widget6__item" }, [
                            _c("span", [_vm._v("Replies allowed")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formattedMessage.allowReplies
                                    ? "Yes"
                                    : "No"
                                )
                              ),
                            ]),
                          ]),
                          _vm.canToggleVisibility
                            ? _c("div", { staticClass: "kt-widget6__item" }, [
                                _c("span", [_vm._v("Share on Portfolio")]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value:
                                              "This message will be visible via the student portfolio page",
                                            expression:
                                              "'This message will be visible via the student portfolio page'",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "checkbox",
                                            disabled: _vm.saving,
                                          },
                                          domProps: {
                                            checked:
                                              _vm.formattedMessage.public,
                                          },
                                          on: { change: _vm.toggleVisibility },
                                        }),
                                        _c("span"),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.$_userMixins_isSchoolUser &&
                          _vm.formattedMessage &&
                          _vm.formattedMessage.isOwner
                            ? _c("div", { staticClass: "kt-widget6__item" }, [
                                _c("span", [_vm._v("Allow Replies")]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                  },
                                  [
                                    _c("label", [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled: _vm.saving,
                                        },
                                        domProps: {
                                          checked:
                                            _vm.formattedMessage.allowReplies,
                                        },
                                        on: { change: _vm.toggleAllowReplies },
                                      }),
                                      _c("span"),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                !_vm.formattedMessage.isSchoolAnnouncement &&
                _vm.formattedRecipients.length &&
                (_vm.schoolStaffId || _vm.formattedMessage.isOwner)
                  ? _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__head" }, [
                        _c("div", { staticClass: "kt-portlet__head-label" }, [
                          _c(
                            "span",
                            { staticClass: "kt-portlet__head-icon" },
                            [
                              _c("SVGIcon", {
                                attrs: {
                                  name: "users",
                                  "hex-color": `#0d74cb`,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("h3", { staticClass: "kt-portlet__head-title" }, [
                            _vm._v(
                              " Recipient" +
                                _vm._s(
                                  _vm.formattedRecipients.length > 1 ? "s" : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "kt-portlet__head-toolbar" }),
                      ]),
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("div", { staticClass: "tab-content student-list" }, [
                          _c("div", { staticClass: "tab-pane active" }, [
                            _c(
                              "div",
                              { staticClass: "kt-widget4" },
                              _vm._l(
                                _vm.formattedRecipients,
                                function (recipient, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `recipient_${recipient.messageRecipientId}_${idx}`,
                                      staticClass: "kt-widget4__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget4__pic kt-widget4__pic--pic",
                                        },
                                        [
                                          _c(
                                            "UserAvatar",
                                            {
                                              attrs: {
                                                "avatar-user":
                                                  recipient.user || {},
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-badge kt-badge--lg kt-badge--success",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user
                                                          ? `${
                                                              recipient.user
                                                                .guardianFirstName ||
                                                              recipient.user
                                                                .firstName
                                                            }`.substring(0, 1)
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget4__info" },
                                        [
                                          recipient.schoolStaffId &&
                                          recipient.user
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "kt-widget4__username",
                                                  attrs: {
                                                    to: {
                                                      name: "TeacherInfo",
                                                      params: {
                                                        schoolEmail:
                                                          recipient.user
                                                            .schoolEmail,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user.lastName
                                                      ) +
                                                      ", " +
                                                      _vm._s(
                                                        recipient.user.firstName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : recipient.studentEnrollmentId
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "kt-widget4__username",
                                                  attrs: {
                                                    to: {
                                                      name: "StudentInfo",
                                                      params: {
                                                        studentEnrollmentId:
                                                          recipient.studentEnrollmentId,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user.lastName
                                                      ) +
                                                      ", " +
                                                      _vm._s(
                                                        recipient.user.firstName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-widget4__username",
                                                  attrs: { href: "#" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user
                                                          ? `${
                                                              recipient.user
                                                                .guardianFirstName ||
                                                              recipient.user
                                                                .firstName
                                                            } ${
                                                              recipient.user
                                                                .guardianLastName ||
                                                              recipient.user
                                                                .lastName
                                                            }`
                                                          : "Unknown user"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                          recipient.schoolStaffId &&
                                          recipient.user
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-widget4__text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user
                                                          .jobTitle || "Staff"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : recipient.studentEnrollmentId &&
                                              recipient.user
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-widget4__text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        recipient.user.extStudentId.substring(
                                                          0,
                                                          3
                                                        )
                                                      ) +
                                                      "-" +
                                                      _vm._s(
                                                        recipient.user.extStudentId.substring(
                                                          3,
                                                          6
                                                        )
                                                      ) +
                                                      "-" +
                                                      _vm._s(
                                                        recipient.user.extStudentId.substring(
                                                          6,
                                                          9
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : recipient.studentGuardianId
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-widget4__text",
                                                },
                                                [_vm._v(" Guardian ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      recipient.messageRead
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-label-success btn-bold",
                                            },
                                            [_vm._v("Read")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget4__pic kt-widget4__pic--icon" }, [
      _c("i", { staticClass: "fa fa-paperclip" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("span", { staticClass: "kt-portlet__head-icon kt-font-primary" }, [
          _c("i", { staticClass: "la la-weixin" }),
        ]),
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Replies "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }