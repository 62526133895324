<template>
<div :key="`key_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title text-nowrap text-truncate">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="communication"
                    />
                    {{ message ? message.messageSubject || 'Communication' : 'Communication' }}
                </h3>
            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <button
                        v-if="formattedMessage && !formattedMessage.deleted && canDelete"
                        type="button"
                        class="btn btn-label-danger btn-bold btn-icon"
                        :disabled="saving"
                        @click.stop.prevent="setMessageDeleted(true)"
                    >
                        <i class="fa fa-trash-alt" />
                    </button>
                    <button
                        v-if="formattedMessage && formattedMessage.deleted && canDelete"
                        type="button"
                        class="btn btn-label-success btn-bold btn-icon"
                        :disabled="saving"
                        @click.stop.prevent="setMessageDeleted(false)"
                    >
                        <i class="fa fa-history" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="formattedMessage" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-8">
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <h6 v-if="!formattedMessage.messageBody">
                            No message body.
                        </h6>
                        <div v-else class="w-100 markdown-container">
                            <VueShowdown
                                :markdown="bodyExpanded ? formattedMessage.messageBody : `${formattedMessage.messageBody.substring(0, 1000)}...`"
                                flavor="github"
                                class="markdown"
                                :options="{ emoji: true }"
                            />
                        </div>
                        <div v-if="canExpandBody">
                            <a
                                href="#"
                                class="kt-link kt-link--state kt-link--primary pull-right"
                                @click.stop.prevent="bodyExpanded = !bodyExpanded"
                            >{{ bodyExpanded ? 'Collapse' : 'Expand' }}</a>
                        </div>
                    </div>
                </div>
                <div v-if="attachments.length" class="row">
                    <div
                        v-for="(attachment, idx) in attachments"
                        :key="`attachment_${idx}`"
                        class="col-4"
                    >
                        <div class="kt-portlet">
                            <div class="kt-portlet__body py-3">
                                <div class="kt-widget4">
                                    <div class="kt-widget4__item">
                                        <div class="kt-widget4__pic kt-widget4__pic--icon">
                                            <i class="fa fa-paperclip" />
                                        </div>
                                        <a
                                            :href="attachment.fileBucketPath"
                                            target="_blank"
                                            class="kt-widget4__title kt-font-bolder"
                                            style="cursor: pointer;"
                                        >
                                            {{ attachment.fileName }}
                                        </a>
                                        <div class="kt-widget4__tools">
                                            <a
                                                :href="attachment.fileBucketPath"
                                                target="_blank"
                                                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                                            >
                                                <i class="flaticon-download" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-chat">
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <span class="kt-portlet__head-icon kt-font-primary"><i class="la la-weixin" /></span><h3 class="kt-portlet__head-title">
                                    Replies
                                </h3>
                            </div>
                        </div>
                        <div
                            v-if="sortedComments.length > 0"
                            class="kt-portlet__body"
                        >
                            <div id="chats">
                                <div
                                    v-for="comment in comments"
                                    :key="`comment_${comment.messageCommentId}`"
                                    class="kt-chat__messages"
                                >
                                    <div
                                        class="kt-chat__message"
                                        :class="comment.fromMe ? 'kt-chat__message--right' : ''"
                                    >
                                        <div
                                            :class="comment.fromMe ? 'kt-bg-light-brand' : 'kt-bg-light-success'"
                                            class="kt-chat__text p-3"
                                        >
                                            {{ comment.messageComment }}
                                        </div>
                                        <div class="kt-chat__user pt-2">
                                            <span class="kt-chat__datetime">
                                                {{ comment.createdAt }}
                                            </span>
                                            <span class="text-muted ml-2">
                                                {{ comment.author }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="sortedComments.length == 0"
                            class="kt-portlet__body"
                        >
                            <h6> No replies yet. </h6>
                        </div>

                        <div v-if="formattedMessage.allowReplies" class="kt-portlet__body">
                            <div class="form-group form-group-last">
                                <textarea
                                    v-model="newComment"
                                    class="form-control"
                                    placeholder="Add a reply"
                                    rows="1"
                                />
                            </div>
                            <div class="row w-100 pt-5">
                                <div class="col-6">
                                    <button
                                        type="button"
                                        :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                        class="btn btn-brand btn-sm btn-upper btn-bold"
                                        :disabled="!newComment || saving"
                                        @click.stop.prevent="saveComment"
                                    >
                                        Post comment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div class="tab-content">
                            <div class="kt-widget6">
                                <div class="kt-widget6__body">
                                    <div class="kt-widget6__item">
                                        <span>Subject</span><span>{{ formattedMessage.messageSubject }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>From</span><span>{{ formattedMessage.author }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>Sent on</span><span class="kt-font-bold kt-font-info">{{ formattedMessage.createdAt }}</span>
                                    </div>
                                    <div v-if="schoolStaffId" class="kt-widget6__item">
                                        <span>Sent to</span><span>{{ formattedMessage.isSchoolAnnouncement ? 'School Wide' : `${formattedRecipients.length} Recipients` }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>Visibility</span><span>{{ formattedMessage.public ? 'Public' : 'Private' }}</span>
                                    </div>
                                    <div class="kt-widget6__item">
                                        <span>Replies allowed</span><span>{{ formattedMessage.allowReplies ? 'Yes' : 'No' }}</span>
                                    </div>
                                    <div v-if="canToggleVisibility" class="kt-widget6__item">
                                        <span>Share on Portfolio</span>
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label v-b-tooltip.hover="'This message will be visible via the student portfolio page'">
                                                <input
                                                    type="checkbox"
                                                    :checked="formattedMessage.public"
                                                    :disabled="saving"
                                                    @change="toggleVisibility"
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                    <div v-if="$_userMixins_isSchoolUser && formattedMessage && formattedMessage.isOwner" class="kt-widget6__item">
                                        <span>Allow Replies</span>
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    :checked="formattedMessage.allowReplies"
                                                    :disabled="saving"
                                                    @change="toggleAllowReplies"
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!formattedMessage.isSchoolAnnouncement && formattedRecipients.length && (schoolStaffId || formattedMessage.isOwner)"
                    class="kt-portlet"
                >
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon">
                                <SVGIcon
                                    name="users"
                                    :hex-color="`#0d74cb`"
                                />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                Recipient{{ formattedRecipients.length > 1 ? 's' : '' }}
                            </h3>
                        </div>

                        <div class="kt-portlet__head-toolbar" />
                    </div>
                    <div class="kt-portlet__body">
                        <div class="tab-content student-list">
                            <div class="tab-pane active">
                                <div class="kt-widget4">
                                    <div
                                        v-for="(recipient, idx) in formattedRecipients"
                                        :key="`recipient_${recipient.messageRecipientId}_${idx}`"
                                        class="kt-widget4__item"
                                    >
                                        <div class="kt-widget4__pic kt-widget4__pic--pic">
                                            <UserAvatar :avatar-user="recipient.user || {}">
                                                <div class="kt-badge kt-badge--lg kt-badge--success">
                                                    {{ recipient.user ? `${recipient.user.guardianFirstName || recipient.user.firstName}`.substring(0, 1) : '-' }}
                                                </div>
                                            </UserAvatar>
                                        </div>

                                        <div class="kt-widget4__info">
                                            <router-link
                                                v-if="recipient.schoolStaffId && recipient.user"
                                                :to="{name: 'TeacherInfo', params: {schoolEmail: recipient.user.schoolEmail}}"
                                                class="kt-widget4__username"
                                            >
                                                {{ recipient.user.lastName }}, {{ recipient.user.firstName }}
                                            </router-link>
                                            <router-link
                                                v-else-if="recipient.studentEnrollmentId"
                                                :to="{name: 'StudentInfo', params: { studentEnrollmentId: recipient.studentEnrollmentId }}"
                                                class="kt-widget4__username"
                                            >
                                                {{ recipient.user.lastName }}, {{ recipient.user.firstName }}
                                            </router-link>
                                            <a
                                                v-else
                                                href="#"
                                                class="kt-widget4__username"
                                            >
                                                {{ recipient.user ?
                                                    `${recipient.user.guardianFirstName || recipient.user.firstName} ${recipient.user.guardianLastName || recipient.user.lastName}`
                                                    : 'Unknown user' }}
                                            </a>
                                            <p
                                                v-if="recipient.schoolStaffId && recipient.user"
                                                class="kt-widget4__text"
                                            >
                                                {{ recipient.user.jobTitle || 'Staff' }}
                                            </p>
                                            <p
                                                v-else-if="recipient.studentEnrollmentId && recipient.user"
                                                class="kt-widget4__text"
                                            >
                                                {{ recipient.user.extStudentId.substring(0, 3) }}-{{
                                                    recipient.user.extStudentId.substring(3, 6)
                                                }}-{{ recipient.user.extStudentId.substring(6, 9) }}
                                            </p>
                                            <p
                                                v-else-if="recipient.studentGuardianId"
                                                class="kt-widget4__text"
                                            >
                                                Guardian
                                            </p>
                                        </div>
                                        <span v-if="recipient.messageRead" class="btn btn-sm btn-label-success btn-bold">Read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
// @ts-ignore
import { VueShowdown } from 'vue-showdown';
import moment from 'moment';
import Types from '../../store/Types';
import userMixins from '../../store/mixins/userMixins';
import teacherMixins from '../../store/mixins/teacherMixins';
import studentMixins from '../../store/mixins/studentMixins';
import UserAvatar from '../UserAvatar.vue';
import * as network from '../../network';
import studentGuardianMixins from '../../store/mixins/studentGuardianMixins';

export default {
    name: 'ViewMessage',
    components: {
        VueShowdown,
        UserAvatar,
    },
    mixins: [userMixins, teacherMixins, studentMixins, studentGuardianMixins],
    data() {
        return {
            saving: false,
            newComment: '',
            recipients: [],
            comments: [],
            attachments: [],
            readStates: [],
            bodyExpanded: false,
        };
    },
    computed: {
        key() {
            return this.$store.state.database.reloadKey;
        },
        ...mapState({
            user: (state) => state.user,
        }),
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId || null;
        },
        teachers() {
            return this.$_teacherMixins_getTeachers();
        },
        students() {
            return this.$_studentMixins_getStudents();
        },
        guardians() {
            return this.$_studentGuardianMixins_getStudentGuardians();
        },
        messages() {
            return this.$store.state.database.messages;
        },
        studentMessages() {
            return this.$store.state.database.studentMessages;
        },
        messageId() {
            return this.$route.params.messageId;
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        message() {
            const { messageId, messages, studentMessages, studentEnrollmentId } = this;
            if (studentEnrollmentId) return studentMessages.find((m) => m.messageId == messageId) || null;
            return messages.find((m) => m.messageId == messageId) || null;
        },
        formattedMessage() {
            const { message } = this;
            return {
                ...message,
                createdAt: moment(message.created).format('MMM Do, YYYY'),
            };
        },
        sortedComments() {
            const { comments } = this;
            return comments.sort((a, b) => {
                const aDate = moment(a.createdAt);
                const bDate = moment(b.createdAt);
                return aDate.isBefore(bDate) ? -1 : 1;
            });
        },
        formattedRecipients() {
            const {
                user, recipients, teachers, students, guardians, readStates,
            } = this;
            const { school } = user;
            return recipients.map((r) => {
                const recipient = { ...r };
                const { schoolStaffId, studentEnrollmentId, studentGuardianId } = recipient;

                if (schoolStaffId) {
                    const u = teachers.find((t) => t.schoolStaffId == schoolStaffId) || null;
                    recipient.user = u;
                    recipient.messageRead = readStates.some((rs) => rs.schoolStaffId == schoolStaffId);
                } else if (studentEnrollmentId) {
                    const u = students.find((s) => s.studentEnrollmentId == studentEnrollmentId) || null;
                    recipient.user = u;
                    recipient.messageRead = readStates.some((rs) => rs.studentEnrollmentId == studentEnrollmentId);
                } else if (studentGuardianId) {
                    const u = [...guardians, {...user, ...school}].find((g) => g.studentGuardianId == studentGuardianId) || null;
                    recipient.user = u;
                    recipient.messageRead = readStates.some((rs) => rs.studentGuardianId == studentGuardianId);
                }

                return recipient;
            }).sort((a, b) => {
                const aName = a.user ? `${a.user.guardianFirstName || a.user.firstName}, ${a.user.guardianLastName || a.user.lastName}` : '';
                const bName = b.user ? `${b.user.guardianFirstName || b.user.firstName}, ${b.user.guardianLastName || b.user.lastName}` : '';
                return aName.localeCompare(bName);
            });
        },
        hasStudent() {
            const { recipients } = this;
            return recipients.some((r) => r.studentEnrollmentId);
        },
        canExpandBody() {
            const { formattedMessage } = this;
            if (!formattedMessage) return false;

            return formattedMessage.messageBody.length > 1000;
        },
        canToggleVisibility() {
            const { formattedMessage, hasStudent } = this;
            if (!formattedMessage) return false;

            const { isSchoolAnnouncement, isOwner } = formattedMessage;
            return this.$_userMixins_isSchoolUser && !isSchoolAnnouncement && isOwner && hasStudent;
        },
        canDelete() {
            const { recipients, user, message } = this;
            const { isSchoolAnnouncement } = message;
            const { school } = user;
            const { schoolStaffId, studentEnrollmentId, studentGuardianId } = school;

            if (this.studentEnrollmentId && message.studentEnrollmentId) return message.studentEnrollmentId == studentEnrollmentId;

            if (!isSchoolAnnouncement && recipients.length) {
                if (schoolStaffId) return recipients.some((r) => r.schoolStaffId == schoolStaffId);
                else if (studentGuardianId) return recipients.some((r) => r.studentGuardianId == studentGuardianId);
                else if (studentEnrollmentId) return recipients.some((r) => r.studentEnrollmentId == studentEnrollmentId);
            }

            return isSchoolAnnouncement;
        },
    },
    watch: {
        messageId() {
            this.populate();
        },
        key() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;
            const { user, messageId } = v;
            const { school, isSuperAdmin } = user;
            const { schoolId, schoolTermId, externalSchoolId } = school;
            v.recipients = [];
            v.comments = [];
            v.readStates = [];

            network.communication.getMessageData({ url: { schoolId, schoolTermId, messageId } }, (err, res) => {
                if (err) return v.showError(err);
                const { message } = v;
                v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, messageRead: isSuperAdmin ? ['00X000'].includes(externalSchoolId) : true }]);
                const { messageRecipients, messageComments, attachments, readStates } = res;
                v.recipients = messageRecipients;
                v.comments = messageComments;
                v.attachments = attachments;
                v.readStates = readStates;
                v.scrollToChatEnd();
            });
        },
        scrollToChatEnd() {
            const { comments } = this;
            if (!comments.length) return;
            this.$nextTick(() => {
                const lastChat = document.getElementById('chats');
                if (lastChat) lastChat.scrollTop = lastChat.scrollHeight;
            });
        },
        saveComment() {
            const v = this;
            if (v.saving || !v.newComment) return;
            const { user, message, messageId, newComment, studentEnrollmentId } = v;
            if (!messageId) return;
            const { school } = user;
            const { schoolId, schoolTermId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    messageId,
                },
                body: {
                    comment: newComment,
                },
            };

            network.communication.postComment(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { comments } = res;
                v.comments.push(...comments);
                v.newComment = '';
                v.readStates = [];
                const { created } = comments[0];

                if (studentEnrollmentId) {
                    v.$store.commit(Types.mutations.SET_DB_STUDENT_MESSAGES, [{ ...message, modified: created }]);

                    const found = v.$store.state.database.messages.find((m) => m.messageId == messageId) || null;
                    if (found) v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, modified: created, studentEnrollmentId: null }]);
                } else v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, modified: created }]);
            });
        },
        setMessageDeleted(deleted) {
            const v = this;
            const { user, messageId } = v;
            if (v.saving || !messageId) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    messageId,
                },
                body: {
                    deleted,
                },
            };

            network.communication.setMessageDeleted(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { message } = v;
                v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, deleted }]);
            });
        },
        toggleVisibility() {
            const v = this;
            const { user, messageId, message, studentEnrollmentId } = v;
            if (v.saving || !messageId) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { allowReplies, notifyEmail, smsEnabled, isSchoolAnnouncement } = message;
            if (isSchoolAnnouncement) return;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    messageId,
                },
                body: {
                    settings: {
                        public: !message.public,
                        allowReplies,
                        notifyEmail,
                        smsEnabled,
                    },
                },
            };

            network.communication.setMessageSettings(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);

                if (studentEnrollmentId) {
                    v.$store.commit(Types.mutations.SET_DB_STUDENT_MESSAGES, [{ ...message, public: !message.public }]);

                    const found = v.$store.state.database.messages.find((m) => m.messageId == messageId) || null;
                    if (found) v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, public: !message.public, studentEnrollmentId: null }]);
                } else v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, public: !message.public }]);
            });
        },
        toggleAllowReplies() {
            const v = this;
            const { user, messageId, message, studentEnrollmentId } = v;
            if (v.saving || !messageId) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { allowReplies, notifyEmail, smsEnabled } = message;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    messageId,
                },
                body: {
                    settings: {
                        allowReplies: !allowReplies,
                        public: message.public,
                        notifyEmail,
                        smsEnabled,
                    },
                },
            };

            network.communication.setMessageSettings(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);

                if (studentEnrollmentId) {
                    v.$store.commit(Types.mutations.SET_DB_STUDENT_MESSAGES, [{ ...message, allowReplies: !allowReplies }]);

                    const found = v.$store.state.database.messages.find((m) => m.messageId == messageId) || null;
                    if (found) v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, allowReplies: !allowReplies, studentEnrollmentId: null }]);
                } else v.$store.commit(Types.mutations.SET_DB_MESSAGES, [{ ...message, allowReplies: !allowReplies }]);
            });
        },
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>
div#chats {
    height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.point-container {
    font-weight: 600;
    font-size: 1.3rem;
}

div.kt-widget4__pic {
    max-height: 64px;
}
div.student-list {
    max-height: 320px;
    overflow-x: clip;
    overflow-y: scroll;
}
a.kt-widget__username {
    white-space: nowrap;
}
div.student-container {
    width: 100%;
    max-height: 72px;
    overflow-x: clip;
    overflow-y: scroll;
}
div.student-pic {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.kt-widget__subtitle {
    font-size: 1rem;
}
.kt-widget__subtitle span {
    font-size: 1rem;
}
</style>

<style scoped>
.kt-widget--user-profile-1 span {
  line-height: 0;
}

.kt-widget__username {
  font-size: 14px !important;
}

.kt-widget__media {
  width: 25%;
  max-width: 25%;
}

 textarea {
    width: 100%;
    /* border: 0 !important;
    outline: none !important;
    background-color: transparent !important; */
    color: #a2a5b9;
}
textarea {
    overflow: auto;
    resize: vertical;
}

.kt_chat__tools a i {
    margin-right: 1.1rem;
    color: #cdd1e9;
    font-size: 1.4rem;
}

.kt-chat .kt-chat__input .kt-chat__toolbar {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

</style>
